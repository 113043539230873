/* General App Styling */
.App {
  text-align: center;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  max-width: 100%; /* Allow app to adjust to full screen width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

@media (max-width: 768px) {
  .App {
    max-width: 100%; /* Use full width on small screens */
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the app takes the full viewport height */
}

.content {
  flex: 1; /* Make the content take the remaining space */
  padding: 20px; /* Optional padding */
}

footer {
  margin-top: auto; /* Push the footer to the bottom */
}


:root {
  --maincolor: #1B263B; 
  --seccolor: #2C3E50;
  --maincolor1: #00AEEF;
  --seccolor1: #BDC3C7;
  --maincolor2: #2ECC71;
  --secolor2:#E67E22;
  --maincolor3: rgba(11, 65, 130);
  --maincolor4: rgb(11, 130, 92);
  --seccolor3: rgba(125, 167, 240);
  --primary: rgba(0, 0, 0, 1);
  --secondary: rgba(0, 0, 0, 0.7);
  --tertiary: rgba(0, 0, 0, 0.5);
  --bgcolor: #F4F8FB;
}


body {
  background-size: cover;
  font-size: 1rem;
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: var(--secondary);
  background-attachment: fixed;
  background-color: var(--bgcolor);
  padding-top: 100px;
  height: 100vh;
}

a,
.a {
  color: var(--primary);
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}

a:hover,
.a:hover {
  color: var(--tertiary);
}

hr {
  opacity: 0.06;
  margin: 20px 30px !important;
}

p,
.p {
  color: var(--secondary);
}

b,
.b {
  color: var(--primary);
}

h1,
.h1 {
  margin-bottom: 0;
}

h2,
.h2 {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 0px;
  font-size: 28px;
  font-weight: 600;
  color: #0B4182;
  text-transform: uppercase;
}
.h2-faucet {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 0px;
  font-size: 28px;
  font-weight: 500;
  color: #0B4182;
  text-transform: none;
  font-family: 'Ubuntu', sans-serif; /* Specify the Ubuntu font */
}
@media screen and (max-width: 400px) {

  h2,
  .h2 {
    font-size: 24px;
  }
}

h3,
.h3 {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 0px;
  font-size: 24px;
  font-weight: 600;
  color: #34495E;
  text-transform: uppercase;
}

@media screen and (max-width: 400px) {

  h3,
  .h3 {
    font-size: 20px;
  }
}

h4,
.h4 {
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 20px;
  text-transform: uppercase;
  color: #0B4182;
}

h5,
.h5 {
  color: #34495E;
  font-weight: 600 !important;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 2px;
}

.h5-faucet {
  color: #34495E;
  font-weight: 400 !important;
  margin-bottom: 14px;
  font-size: 16px;
  margin-top: 2px;
}

h6,
.h6 {
  color: #34495E;
  font-weight: 600 !important;
  font-size: 15px;
  margin-bottom: 4px;
}

@media screen and (max-width: 400px) {

  h6,
  .h6 {
    font-size: 14px;
  }
}


/* Hero Section Styling */
.hero {
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.72) 0%, rgba(125, 167, 240, 0) 100%); 
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.7);
  transition: all 0.5s ease-in-out;
  padding: 50px 20px 50px; 
  text-align: center;
  margin-top: 70px; 
  position: relative; 
  width: 100%;
  overflow: hidden; 
}


.hero-content {
  max-width: 700px;
  margin: auto;
  position: relative;
  z-index: 1; 
  text-align: center;
  color: #fff; 
  padding: 20px;
}

.hero-h1 {
  font-family: 'Ubuntu', sans-serif; /* Specify the Ubuntu font */
  font-weight: 600; /* Set the weight to Medium */
  font-size: 3rem;
  margin-bottom: 20px;
  color: #0b4182;
}

.hero-p {
  font-family: 'Rubik', sans-serif; /* Specify the Rubik font */
  font-size: 1rem;
  margin-bottom: 30px;
}


.faucet-button {
  display: inline-block; /* Make it behave like a button */
  max-width: 100%; /* Full width for small screens */
  padding: 3px 15px;
  font-size: 0.9rem;
  background: rgba(125, 167, 240, 0.44);
  color: #2f3439;
  border: 0.5px solid #0B4182;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.faucet-button:hover {
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background: var(--seccolor3);
}

.mm-button {
  display: inline-block; /* Make it behave like a button */
  max-width: 100%; /* Full width for small screens */
  padding: 2px 15px;
  font-size: 0.9rem;
  background: rgb(224, 121, 47, 0.44);
  color: #763e1a;
  border: 0.5px solid #763e1a;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}


.mm-button:hover {
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background: rgb(212, 125, 64);
}

.pfaucet-button {
  display: inline-block; /* Make it behave like a button */
  max-width: 100%; /* Full width for small screens */
  padding: 3px 15px;
  font-size: 0.9rem;
  background: rgb(133, 76, 230, 0.44);
  color: #2f3439;
  border: 0.5px solid #854ce6;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}


.pfaucet-button:hover {
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background: rgb(133, 76, 230);
}

/* Adjust styles for small screens */
@media (max-width: 460px) {
  .faucet-button {
    width: 100%; /* Full width for small screens */
    max-width: 100%; /* Ensure no fixed width */
  }
}
.button-text {
  margin-left: 4px; /* Adjust this value for more or less space */
}
.icon {
  font-size: 1.5rem; /* Adjust this value for larger or smaller icons */
  margin-right: 4px; /* Space between icon and text */
  vertical-align: middle; /* Align icons with text */
}
.button-container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  flex-direction: row;
  justify-content: center;
}

.faucet-button {
  margin-right: 40px; /* Add space between buttons */
}

.faucet-button:last-child {
  margin-right: 0; /* Remove margin for the last button */
}

/* Media query for small screens */
@media (max-width: 768px) {
  .faucet-button {
    margin-right: 0; /* Remove margin on small screens */
    margin-bottom: 10px; /* Optional: add some space below each button */
  }
}

.request-button {
  display: inline-block; /* Make it behave like a button */
  padding: 7px 20px;
  font-size: 1.1rem;
  color: rgb(29, 119, 50);
  background: rgb(39, 240, 86);
  border: 0.5px solid rgb(29, 119, 50);
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.request-button:hover {
  transition: all 0.3s ease-in-out;
  background: rgb(40, 167, 69);
  color: #ffffff;
}

.hero-button {
  display: inline-block; /* Make it behave like a button */
  padding: 10px 20px;
  font-size: 1.2rem;
  background: rgba(125, 167, 240, 0.44);
  color: #0B4182;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.hero-button:hover {
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background: var(--seccolor3);
}

/* Cards Section Styling */
.card-section {
  padding: 0; /* Remove padding on the section */
}

.card {
  display: flex; /* Enable flexbox on the card */
  flex-direction: column; /* Arrange children in a column */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  border: none; /* No border for cards */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside the card */
  transition: transform 0.3s; /* Add a transition for hover effect */
  width: 300px;
}

.card-one {
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.72) 0%, rgba(125, 167, 240, 0) 100%); /* Background for Card 1 */
}

.card-two {
  background: linear-gradient(0deg, rgba(125, 167, 240, 0.72) 0%, rgba(125, 167, 240, 0) 100%);  /* Background for Card 2 */
}

.card-three {
  background: linear-gradient(0deg, rgba(126, 243, 153, 0.77) 0%, rgba(126, 243, 153, 0) 100%); /* Background for Card 3 */
}


/* Responsive Styles */
@media (min-width: 992px) { /* Large screens */
  .card-section .row > div:nth-child(1) {
    margin-bottom: 60px; /* Increased gap between Card 1 and Card 2 */
  }

  .card-section .row {
    padding-left: 0; /* Remove left padding */
    padding-right: 0; /* Remove right padding */
  }
}

/* Small screens */
@media (max-width: 767px) { /* Small screens */
  .card-section .row {
    margin-left: 0; /* No margin */
    margin-right: 0; /* No margin */
    padding: 0; /* No padding */
  }

  .card {
    margin: 0; /* Ensure no margin on cards */
    width: 100%; /* Full width for small screens */
  }
}

.card-button {
  display: inline-block; /* Make it behave like a button */
  padding: 5px 20px;
  font-size: 1rem;
  color: #0b4182;
  background: var(--seccolor3);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.card-button:hover {
  transition: all 0.3s ease-in-out;
  background: rgba(11, 65, 130);
  color: #ffffff;
}


.card-three button {
  display: inline-block; /* Make it behave like a button */
  padding: 5px 20px;
  font-size: 1rem;
  color: rgb(29, 119, 50);
  background: rgb(39, 240, 86);

  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Remove underline from the link */
}

.card-three-button:hover {
  transition: all 0.3s ease-in-out;
  background: rgb(40, 167, 69);
  color: #ffffff;
}

.card-image {
  width: 60%; /* Full width to ensure responsiveness */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners for a smoother look */
  margin-bottom: 15px; /* Space below the image */
  object-fit: cover; /* Cover the area while maintaining aspect ratio */
}
.logo {
  width: 140px; /* Full width to ensure responsiveness */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the area while maintaining aspect ratio */
}
.token {
  width: 140px; /* Full width to ensure responsiveness */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the area while maintaining aspect ratio */
}

/* Roadmap Section Styles */
.horizontal-timeline {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping of items */
  margin-top: 40px; /* Space above the timeline */
  padding-bottom: 20px; /* Space at the bottom */
}

.horizontal-timeline .items {
  border-top: 3px solid #e9ecef; /* Top border for the timeline */
  margin-top: 40px; /* Space between header and timeline */
  display: inline-block; /* Ensure items are inline for horizontal scrolling */
}

.horizontal-timeline .items .items-list {
  display: inline-block; /* Make items align horizontally */
  position: relative;
  text-align: center;
  padding-top: 70px; /* Padding for spacing */
}

/* Event Date Badge */
.horizontal-timeline .items .items-list .event-date {
  position: absolute;
  top: 36px; /* Position below the timeline */
  left: 0;
  right: 0;
  width: 75px; /* Width of the badge */
  margin: 0 auto; /* Center the badge */
  font-size: 0.9rem; /* Font size for the date */
  padding-top: 8px; /* Space above the date */
}


/* Adjust styles for different statuses */
.horizontal-timeline .items .items-list.completed .event-date {
  background-color: #28a745; /* Green */
}

.horizontal-timeline .items .items-list.in-progress .event-date {
  background-color: #ffc107; /* Yellow */
}

.horizontal-timeline .items .items-list.planned .event-date {
  background-color: #17a2b8; /* Blue */
}

.horizontal-timeline .items .items-list:before {
  content: "";
  position: absolute;
  height: 36px; /* Height of the vertical line */
  border-right: 2px dashed #dee2e6; /* Dashed line */
  top: 0; /* Start at the top */
}

.horizontal-timeline .items .items-list .event-date {
  position: absolute;
  top: 36px; /* Position below the timeline */
  left: 0;
  right: 0;
  width: 75px; /* Width of the badge */
  margin: 0 auto; /* Center the badge */
  font-size: 0.9rem; /* Font size for the date */
  padding-top: 8px; /* Space above the date */
}

/* Adjust styles for larger screens */
@media (min-width: 1140px) {
  .horizontal-timeline .items .items-list {
    display: inline-block; /* Display in a row */
    width: auto; /* Allow auto width for items */
    padding-top: 45px; /* Adjust padding */
  }

  .horizontal-timeline .items .items-list .event-date {
    top: -40px; /* Adjust position for larger screens */
  }
}


/* Footer Styling */
.footer {
  background-color: #f0f0f0;
  color: white;
  border-top: .05px solid #bac8e3;
  padding: 20px 0; /* Padding for footer */
  width: 100vw; /* Full viewport width */
  position: relative; /* Positioned relative to its normal flow */
  left: 50%; /* Centering */
  right: 50%; /* Centering */
  margin-left: -50vw; /* Pull it back */
  margin-right: -50vw; /* Pull it back */
}

/* Footer Container */
.footer-container {
  max-width: 1200px; /* Same max-width as the rest of the content */
  margin: auto; /* Center the container */
  padding: 0 20px; /* Add horizontal padding */
}

/* Footer Columns */
.footer-columns {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between columns */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
 margin-bottom: 30px; /* Margin for bottom spacing */
  align-items: center; /* Center align columns */
  text-align: center; /* Center align text */
}

/* Footer Logo */
.footer-logo {
  flex: 2; /* Logo takes twice the space compared to other columns */
  text-align: left; /* Align logo text to the left */
}

.footer-logo h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Footer Column Headings */
.column {
  flex: 1; /* Allow even distribution */
  min-width: 150px; /* Set minimum width for columns */
  margin-right: 20px; /* Add right margin for spacing */
  text-align: left;
}

.h3-footer {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 0px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #0C4283;
  text-transform: none;
  font-family: 'Ubuntu', sans-serif; /* Specify the Ubuntu font */
}

.column-h3 {
  font-size: 0.9rem;
  /*margin-bottom: 10px;*/
  text-align: left; /* Default alignment for large screens */
}

.column-h3 ul {
  list-style: none; /* Remove bullet points */
  padding-left: 0; /* Remove left padding */
}
.column-h3 ul li {
  /*text-align: left; /* Align links to the left */
  margin-bottom: 10px; /* Add some spacing between the list items */
}
.column-h3 ul li a {
  color: var(--maincolor); /* Adjust link color as needed */
  text-decoration: none; /* Remove underline */
}
.column-h3 ul li a:hover {
  text-decoration: underline; /* Optional: Add underline on hover */
}

/* Responsive adjustments for footer links */
@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column; /* Stack footer columns vertically */
    align-items: center;
  }
  
  .column {
    text-align: center;
    margin-bottom: 10px;
  }
}

/* Responsive: Align text center for small screens */
@media screen and (max-width: 768px) {
  .column-h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: center; /* Center align for small screens */
  }
}

@media screen and (max-width: 400px) {
  .h3-footer {
    font-size: 1rem;
    text-align: center; /* Center align for extra small screens */
  }
  .column-h3 {
    font-size: 0.8rem;
    margin-bottom: 10px;
    text-align: center; /* Center align for extra small screens */
  }
}

/* Footer for small screens */
.footer {
  padding: 10px 0;
}

.footer-columns {
  flex-direction: row;
  align-items: center;
}

.footer-logo {
  margin-bottom: 20px;
}

.column {
  text-align: center;
  margin-bottom: 10px;
}

/* Column Links */
.column ul {
  list-style: none;
  padding: 0;
}

.column ul li {
  margin-bottom: 5px;
}

.column ul li a {
  color: var(--seccolor);
  text-decoration: none;
}
.column ul li a:hover{
  color: var(--maincolor);
  text-decoration: none;
}

/* Footer Social Links */
.footer-social ul {
  list-style: none;
  display: flex;
  justify-content: center;
}

.footer-social ul li {
  margin-right: 20px;
}

.footer-social ul li a {
  color: #61dafb;
  text-decoration: none;
  font-size: 1.5rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column; /* Stack columns vertically */
  }

  .footer-logo {
    margin-bottom: 20px; /* Add margin for spacing */
    text-align: center; /* Center align on small screens */
  }

  .column {
    margin-right: 0; /* Remove right margin for stacked layout */
    margin-bottom: 20px; /* Add bottom margin for spacing */
    text-align: center; /* Center align on small screens */
  }
}



/* Add this to App.css */
.particles {
  position: absolute; /* Position particles absolutely */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  bottom: 0; /* Align to the bottom */
  pointer-events: none; /* Prevent mouse events */
}

.matrix-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the effect is behind the content */
}



/* FAQ */
.faq {
  padding: 40px 20px; /* Adjust padding for the FAQ section */
  background-color: var(--bgcolor); /* Set the background color of the FAQ section */
}

.accordion-button {
  background-color: var(--bgcolor);
  border: none; /* Remove default border */
  color: #0B4182; /* Text color */
}

.accordion-button:hover,
.accordion-button:focus {
  background-color: #d9e7ff; /* Light blue background on hover */
  color: #0B4182; /* Ensure text color remains */
}

.accordion-body {
  padding: 15px; /* Padding inside the accordion body */
  background-color: var(--bgcolor); /* Remove background color for body */
  text-align: left;
}

/* Remove borders and shadows */
.accordion-item {
  border: none; /* Remove border */
}

.accordion-item:last-child .accordion-body {
  border-bottom: none; /* Remove border from last item's body */
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  /* box-shadow: var(--bs-accordion-btn-focus-box-shadow);*/
}

/* Alert Box for Network and MetaMask Warnings */
.alert {
  padding: 20px;
  max-width: 100%; /* Full width for small screens */
  margin: 0 auto;
  box-sizing: border-box;
}

/* Adjust styles for small screens */
@media (max-width: 460px) {
  .alert {
    width: 100%; /* Full width for small screens */
    max-width: 100%; /* Ensure no fixed width */
  }
}

/* Ensuring full width and responsive layout for all content */
.container, .faucet-container, .description {
  max-width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto;
}

/* Add this to App.css */
.faucet-container {
  max-width: 100%;
  padding: 20px 15px;
  box-sizing: border-box;
}

/* Ensure description sections do not overflow */
#description {
  width: 100%; 
  max-width: 760px; /* Set max width to 460px */
  box-sizing: border-box;
  margin: 0 auto; /* Center the container */
}

#intro {
  text-align: justify; /* Justify text alignment */
  font-size: 1rem; /* Adjust the font size if needed */
}

.text-center-three {
  font-weight: 600; /* Make the text bold */
  font-family: 'Ubuntu', sans-serif; /* Specify the Ubuntu font */
}

.fetched-content {
  font-size: 0.9rem; /* Set the font size for the fetched content */
}

.ampersand {
  padding-left: 10px;  /* Add left padding to the "&" */
  padding-right: 10px; /* Add right padding to the "&" */
}
.read-more-container {
  min-height: 40px; /* Minimum height to keep layout consistent */
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
}

.read-more-container {
  min-height: 40px; /* Ensures consistent height across items */
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
  margin-bottom: 20px; /* Adds space below the button */
}

.empty-button {
  visibility: hidden; /* Hide the button, but maintain the same height */
  margin-bottom: 20px; /* Ensures the same spacing below items without a button */
}

.footer-social ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 10px;
}

/*Footer Social*/
.footer-social ul li {
  display: inline-block;
}

.footer-social ul li a {
  color: #6b6d70;/* Adjust the color as needed */
  font-size: 1.5rem; /* Adjust the size of the icons */
  transition: color 0.3s ease;
}

.footer-social ul li a:hover {
  color: #0C4283; /* Change the color on hover */
}


.justify {
  text-align: justify;
}




/* General Header Styling */
.header {
  background-color: #f0f0f0;
  padding: 20px 0;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000; /* Ensure the header is above other content */
  border-bottom: 0.05px solid #bac8e3;
}

/* Responsive Header */
.header {
  padding: 20px 0;
}

@media (max-width: 768px) {
  .header {
    padding: 10px 0; /* Reduce padding on small screens */
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo img {
  width: 140px;
}

/* Hamburger Icon */
.hamburger {
  display: none;
  font-size: 2rem;
  color: #34495E;
  cursor: pointer;
}

/* Main Navigation */
.navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navigation ul li {
  position: relative;
  margin-left: 30px;
}

.navigation ul li a:hover {
  color: var(--maincolor);
}

.dropdown .dropdown-toggle {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: #0B4182;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f0f0f0;
  top: 100%;
  left: 0;
  min-width: 180px;
  /*box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);*/
  padding: 10px 0;
  z-index: 1001;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu li a {
  font-size: 1rem;
  color: #34495E;
  text-decoration: none;
}

.dropdown-menu li a:hover {
  color: #61dafb;
}

/* Responsive Design for Mobile Screens */
@media screen and (max-width: 768px) {
  .hamburger {
    display: block; /* Show hamburger on small screens */
  }

  .navigation {
    display: none; /* Hide the navigation by default */
    position: absolute;
    top: 70px; /* Position below the header */
    left: 0;
    right: 0;
    background-color: #f0f0f0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 999;
    text-align: center;
  }

  .navigation.open {
    display: flex; /* Show the menu when the hamburger is clicked */
  }



  .navigation ul li {
    margin: 15px 0;
  }

  .dropdown-menu {
    position: static; /* Remove absolute positioning on mobile */
    width: 100%; /* Full width on mobile */
    box-shadow: none;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-menu li {
    text-align: center;
  }
}


/* Header Styling */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Logo Animation */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Header Styling */
.header {
  background-color: #f0f0f0;
  padding: 64px 0 1.1rem; /* Space for the flip notification */
  border-bottom: .05px solid #bac8e3;
  width: 100vw; /* Full viewport width */
  position: fixed; /* Fixed positioning */
  top: 0; /* Align at the top */
  left: 0; /* Align with the left edge */
  right: 0; /* Align with the right edge */
  z-index: 500; /* Stay above other content */
  
  
}

/* Container for Header */
.container {
  max-width: 1200px; /* Same max-width as the rest of the content */
  margin: auto; /* Center the container */
  display: flex; /* Use flexbox for alignment */
  justify-content: flex-start; /* Align items to the left */
  align-items: center; /* Vertically center the items */
  padding: 0 10px; /* Add horizontal padding */
}


/* Hamburger Icon */
.hamburger {
  display: none; /* Hidden by default */
  font-size: 2rem; /* Size of the icon */
  cursor: pointer; /* Pointer cursor on hover */
  color: var(--maincolor3); /* Change color as needed */
  margin-left: auto; /* Align to the right */
  padding: 0 20px; /* Add horizontal padding from the edges */
}

/* Navigation Styling */
.navigation {
  display: flex; /* Default for larger screens */
  /*padding-left: 30px;*/
}

.navigation ul {
  list-style-type: none;
  display: flex; /* Horizontal layout for larger screens */
  margin-left: auto; /* Push the menu to the right */
  color: var(--maincolor3);
}

.navigation ul li {
  margin-right: 30px;
  position: relative; /* Position for dropdown */
}

.navigation ul li a {
  color: #0B4182;
  text-decoration: none;
  font-size: 1rem;
}

/* Submenu Styling */
.navigation ul li ul {
  display: none; /* Hide submenu by default */
  position: absolute; /* Position absolutely to create a dropdown */
  background-color: #f0f0f0; /* Background for the submenu */
  padding: 0; /* Remove padding around the submenu */
  z-index: 1000; /* Ensure it appears above other content */
  width: min-content; /* Set width to the longest item length */
 /* max-width: 300px; /* Optional: Set a maximum width */
}
/* Submenu item links */
.navigation ul li ul li a {
  display: block; /* Ensure the link takes up the full width */
  width: 100%; /* Set width to 100% for the link */
  padding: 10px 15px; /* Add padding for better spacing, including right padding */
  text-align: left; /* Align text to the left */
  color: var(--maincolor3); /* Link color */
  text-decoration: none; /* No underline */
  font-size: 0.8rem;
}

.navigation ul li:hover ul {
  display: block; /* Show submenu on hover */
}

.navigation ul li ul li {
  width: 100%; /* Ensure submenu items take full width */
}

.navigation ul li ul li a {
  display: block; /* Ensure the link takes up the full width */
  width: 100%; /* Set width to 100% for the link */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navigation {
    display: none; /* Hide default navigation */
    flex-direction: column; /* Stack nav items vertically */
    position: absolute; /* Overlay navigation */
    top: 120px; /* Position below header */
    left: 0; /* Align to left */
    right: 0; /* Align to right */
    background-color: #f0f0f0; /* Background color for dropdown */
    z-index: 1000; /* Ensure it's above other elements */
    text-align: center; /* Center items in the dropdown */
    width: 100%; /* Make sure it takes full width */
  }
  .navigation ul li ul li a {
    display: block; /* Ensure the link takes up the full width */
    width: 100%; /* Set width to 100% for the link */
    padding: 10px 15px; /* Add padding for better spacing, including right padding */
    text-align: center; /* Align text to the left */
    color: var(--maincolor3); /* Link color */
    text-decoration: none; /* No underline */
    font-size: 0.8rem; /* Font size */
  }

  .navigation.open {
    display: flex; /* Show the menu when open */
  }

  .navigation ul {
    flex-direction: column; /* Stack items vertically */
    width: 100%; /* Full width for the menu */
    padding: 0; /* Remove padding */
  }

  .navigation ul li {
    margin: 10px 0; /* Vertical spacing for items */
  }

  .navigation ul li ul {
    position: relative; /* Ensure submenu positions correctly under the parent */
    width: 100%; /* Extend full width of parent */
    padding: 0; /* Optional: Adjust padding as necessary */
  }
}

  @media (max-width: 768px) {
  .hamburger {
    display: block; /* Show hamburger icon on small screens */
  }
}

/* Fixed Positioning for Flip Notification and Header */
.notification {
  background: var(--maincolor);
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  position: fixed; /* Keep it fixed at the top */
  top: 0;
  left: 0;
  right: 0;
  font-weight: 800;
  z-index: 1000; /* Make sure it appears above other elements */
  transition: all 0.5s ease; /* Smooth transition */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Stack header items on smaller screens */
    align-items: flex-start;
  }

  .navigation ul {
    flex-direction: column; /* Stack nav items vertically */
    width: 100%;
  }

  .navigation ul li {
    margin: 5px 0; /* Reduce margin */
  }

  .cards {
    flex-direction: column; /* Stack cards vertically */
    align-items: center;
  }

  .card {
    width: 90%; /* Set card width to 90% on small screens */
    margin: 10px 0; /* Add vertical margin */
  }
}


/* Navbar */
.top-bar {
  background: #f0f0f0;
  /*box-shadow: rgb(0, 0, 0, 0.15) 10px 0px 20px;*/
  padding: 10px 0;
  /*margin-bottom: 5px;*/
  position: fixed;
  width: 100%;
  height: 86px;
  top: 40px;
  border-bottom: 0.05px solid #bac8e3;  
  z-index: 1000; /* Ensure it's above other content */
}

.navbar-toggler-icon {
  background-color: #BDC3C7;
}

.navbar-toggler {
  border: 0px;
}
.navbar-nav .dropdown-menu {
  background: #f0f0f0 !important;
  padding: 10px 0;
  border: 0px solid transparent;
  /*box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);*/
  color: var(--tertiary);
}

@media screen and (max-width: 768px) {
  .navbar-nav .dropdown-menu li {
    width: 100%; /* Ensure the list items take full width */
    text-align: center; /* Center the text */
  }
}
.navbar-bkg {
  background-color: #f0f0f0;
}

.dropdown-menu .nav-link {
  color: var(--maincolor3);
  font-size: 13px;
  font-weight: 400;
}

/* Adjust font size for even smaller screens */
@media screen and (max-width: 800px) {
  .dropdown-menu .nav-link {
    font-size: 12px;
  }
}

@media screen and (max-width: 390px) {
  .dropdown-menu .nav-link {
    font-size: 11.5px;
  }
}

.dropdown-menu .nav-link:hover {
  color: var(--seccolor);
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.bg-secondary {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
.navbar-brand {
  margin-right: 2rem;
}

.navbar-toggler {
  color: transparent !important;
}

.menuBtn {
  padding: 20px 30px 16px;
  margin: 0 10px !important;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  border: 0px;
  margin-right: 0px;
  color: var(--tertiary);
  /*text-transform: uppercase;*/
  transition: all 0.5s ease-in-out;
  box-shadow: none;
  width: auto;
}

.menuBtn svg {
  margin-top: -3px;
  margin-right: 7px;
  font-size: 18px;
}

.menuBtn:hover {
  color: var(--primary);
  background-color: transparent;
  border: 0px;
}

@media screen and (max-width: 800px) {
  .menuBtn {
    padding: 16px 10px 10px;
    font-size: 12px;
  }
}

@media screen and (max-width: 390px) {
  .menuBtn {
    padding: 16px 10px 10px;
    font-size: 11.5px;
  }
}

.dropdown-padding {
  padding: 12px 0 8px
}

@media screen and (max-width: 992px) {
  .dropdown-padding {
    padding: 8px 10px 2px
  }
}
.no-underline {
  text-decoration: none;
}
.no-underline:hover {
  text-decoration: underline; /* Optional: Add underline on hover */
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
}




       /* Card layout */
       .card-layout {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .token-card {
        width: 250px;
        margin: 15px;
        text-align: center;
        padding: 20px;
        border-radius: 10px;
        background: linear-gradient(0deg, rgba(125, 167, 240, 0.17) 0%, rgba(125, 167, 240, 0) 100%);
        -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12px);
        color: rgba(0, 0, 0, 0.7);
        transition: all 0.5s ease-in-out;
        position: relative;
    }

    .token-card img {
        max-width: 90px;
        margin: 10px 0;
    }
    
    @media (min-width: 768px) {
      .token-card {
        width: 250px; /* Fixed width for larger screens */
      }
    }

    .metamask{
      max-width: 22px;
      height:auto;
      margin: 10px 0;
  }
  @media (max-width: 460px) {
    .metamask {
      width: 20px; /* Fixed width for larger screens */
      height:auto;
    }
  }

    /* Button styling */

    .btn-primary {
        padding: 10px;
        font-size: 16px;
        background: rgba(125, 167, 240, 0.46);
        color: #0B4182;
        border-radius: 5px;
        box-shadow: none;
        font-weight: 500;
    }

    .btn-primary:hover {
        color: rgba(255, 255, 255);
        background: rgba(46, 204, 113, 0.72);
        box-shadow: none;
    }

    /* Small screens dropdown */
    .dropdown-layout {
        display: none;
    }

    @media (max-width: 768px) {
        .dropdown-layout {
            display: block;
            margin-top: 30px;
        }

        .dropdown-layout select {
            font-size: 18px;
            padding: 12px;
            border-radius: 5px;
        }

        .dropdown-header {
            font-size: 22px;
            margin-bottom: 10px;
        }

        #go-to-token {
            font-size: 18px;
            padding: 12px;
        }

        .faucet-description p {
            font-size: 16px;
            color: #444;
            margin-top: 10px;
        }
    }

    @media (min-width: 769px) {
        .dropdown-layout {
            display: none;
        }
    }

    .footer-menu {
      display: none; /* Hide the menu by default */
    }
    
    .footer-menu.show {
      display: block; /* Show menu when class is added */
    }
    
    @media (min-width: 769px) {
      .footer-menu {
        display: block !important; /* Ensure the menu is always visible on large screens */
      }
    
      .dropdown-toggle {
        cursor: default; /* Remove pointer cursor on large screens */
      }
    }
    
    @media (max-width: 768px) {
      .footer-menu {
        display: none; /* Menu is hidden by default on small screens */
      }
    
      .footer-social {
        margin-top: 20px; /* Add some margin for spacing */
      }
    }
    